

export default [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/home.vue'),
        
    },
    {
        path: '/commingsoon',
        name: 'commingsoon',
        component: () => import('../views/commingsoon.vue'),
        
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact.vue'),
        
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue'),
        
    },





    //production_equipement
    {
        path: '/production',
        name: 'production',
        component: () => import('../views/Categories/production_equipement.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system',
        name: 'board_handling_system',
        component: () => import('../views/subcategories/Production_equipement/bord_handling_system.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading',
        name: 'loading_unloading',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/loading_unloading.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/bare_board_loader',
        name: 'bare_board_loader',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/BARE BOARD LOADER(VACUUM PICK-UP TYPE).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/dual_magazine_loader',
        name: 'dual_magazine_loader',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/DUAL MAGAZINE LOADERUNLOADER(CE Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/magazine_vacuum_loader',
        name: 'magazine_vacuum_loader',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE & VACUUM LOADER(Combination).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/magazine_loader_90',
        name: 'magazine_loader_90',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (90˚ Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/magazine_loader_ergonomic',
        name: 'magazine_loader_ergonomic',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE LOADER (Ergonomic Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/magazine_loader_standard',
        name: 'magazine_loader_standard',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE_LOADER_(Standard Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/magazine_unloader_90',
        name: 'magazine_unloader_90',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE UNLOADER (90˚ Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/magazine_unloader_ergonomic',
        name: 'magazine_unloader_ergonomic',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE UNLOADER (Ergonomic Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/magazine_unloader_standard',
        name: 'magazine_unloader_standard',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/MAGAZINE UNLOADER (Standard Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/single_magazine_loader',
        name: 'single_magazine_loader',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/SINGLE MAGAZINE LOADER (CE Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/loading_unloading/single_magazine_unloader',
        name: 'single_magazine_unloader',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Loading_Unloading/SINGLE MAGAZINE UNLOADER (CE Type).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/conveyor',
        name: 'conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Conveyor/conveyor.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/conveyor/connecting_conveyor',
        name: 'connecting_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Conveyor/CONNECTING CONVEYOR.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/conveyor/gateway_conveyor',
        name: 'gateway_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Conveyor/GATEWAY(TELESCOPIC) CONVEYOR.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/conveyor/inspection_conveyor',
        name: 'inspection_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Conveyor/INSPECTION CONVEYOR.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/conveyor/pcb_claning_conveyor',
        name: 'pcb_claning_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Conveyor/PCB CLEANING CONVEYOR.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/conveyor/shuttle_conveyor',
        name: 'shuttle_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Conveyor/SHUTTLE CONVEYOR.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/buffer',
        name: 'buffer',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Buffer/buffer.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/buffer/spi_aoi_ng_buffer',
        name: 'spi_aoi_ng_buffer',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Buffer/spi_aoi_ng_buffer.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/buffer/chain_buffer',
        name: 'chain_buffer',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Buffer/chain_buffer.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/buffer/cooling_buffer',
        name: 'cooling_buffer',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Buffer/cooling_buffer.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/buffer/curing_chain_buffer',
        name: 'curing_chain_buffer',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Buffer/curing_chain_buffer.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/buffer/magazine_buffer',
        name: 'magazine_buffer',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Buffer/magazine_buffer.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/buffer/rack_buffer',
        name: 'rack_buffer',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Buffer/rack_buffer.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/Flip_Turning',
        name: 'Flip_Turning',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Flip_Turning/Flip_Turning.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/Flip_Turning/90_turning_conveyor',
        name: '90_turning_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Flip_Turning/90˚ TURNING CONVEYOR.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/Flip_Turning/inverted_board_flipper',
        name: 'inverted_board_flipper',
        component: () => import('../views/products/production_equipement/Bord_handling_system/Flip_Turning/INVERTER(BOARD FLIPPER).vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/otherss',
        name: 'otherss',
        component: () => import('../views/products/production_equipement/Bord_handling_system/otherss/otherss.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/otherss/manual_insertion_conveyor',
        name: 'manual_insertion_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/otherss/Manual Insertion Conveyor.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/otherss/national_prorack',
        name: 'national_prorack',
        component: () => import('../views/products/production_equipement/Bord_handling_system/otherss/NATIONAL PRORACK.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/otherss/ng_reject_conveyor',
        name: 'ng_reject_conveyor',
        component: () => import('../views/products/production_equipement/Bord_handling_system/otherss/NG Reject Conveyor.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/otherss/nikko_rack',
        name: 'nikko_rack',
        component: () => import('../views/products/production_equipement/Bord_handling_system/otherss/NIKKO RACK.vue'),
        
    },
    {
        path: '/production_equipement/board_handling_system/otherss/pass_thru_type_auto_loader',
        name: 'pass_thru_type_auto_loader',
        component: () => import('../views/products/production_equipement/Bord_handling_system/otherss/PASS THRU TYPE AUTO LOADER.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer',
        name: 'screen_printer',
        component: () => import('../views/products/production_equipement/Screen_Printer/screen_printer.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/sp1_c',
        name: 'sp1_c',
        component: () => import('../views/products/production_equipement/Screen_Printer/SP1-C.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/sp1_w',
        name: 'sp1_w',
        component: () => import('../views/products/production_equipement/Screen_Printer/SP1-W.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/sp2_c',
        name: 'sp2_c',
        component: () => import('../views/products/production_equipement/Screen_Printer/SP2-C.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/sp3_c',
        name: 'sp3_c',
        component: () => import('../views/products/production_equipement/Screen_Printer/SP3-C.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/versaprint_2_elite_plus',
        name: 'versaprint_2_elite_plus',
        component: () => import('../views/products/production_equipement/Screen_Printer/VERSAPRINT 2 ELITE plus .vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/versaprint_2_elite',
        name: 'versaprint_2_elite',
        component: () => import('../views/products/production_equipement/Screen_Printer/VERSAPRINT 2 ELITE.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/versaprint_2_pro_2',
        name: 'versaprint_2_pro_2',
        component: () => import('../views/products/production_equipement/Screen_Printer/VERSAPRINT 2 PRO2.vue'),
        
    },
    {
        path: '/production_equipement/screen_printer/versaprint_2_ultra3',
        name: 'versaprint_2_ultra3',
        component: () => import('../views/products/production_equipement/Screen_Printer/VERSAPRINT 2 ULTRA3.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter',
        name: 'chip_mounter',
        component: () => import('../views/products/production_equipement/Chip_Mounter/chip_mounter.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/hm520hs',
        name: 'hm520hs',
        component: () => import('../views/products/production_equipement/Chip_Mounter/1HM520 HS.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/hm520mf',
        name: 'hm520mf',
        component: () => import('../views/products/production_equipement/Chip_Mounter/2HM520 MF.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/hm510',
        name: 'hm510',
        component: () => import('../views/products/production_equipement/Chip_Mounter/3HM510.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/decanf2',
        name: 'decanf2',
        component: () => import('../views/products/production_equipement/Chip_Mounter/4DECAN F2.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/decanl2',
        name: 'decanl2',
        component: () => import('../views/products/production_equipement/Chip_Mounter/5DECAN L2.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/decans2',
        name: 'decans2',
        component: () => import('../views/products/production_equipement/Chip_Mounter/6DECAN S2.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/decans1',
        name: 'decans1',
        component: () => import('../views/products/production_equipement/Chip_Mounter/7DECAN S1.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/decands',
        name: 'decands',
        component: () => import('../views/products/production_equipement/Chip_Mounter/8DECAN DS.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/sm471plus',
        name: 'sm471plus',
        component: () => import('../views/products/production_equipement/Chip_Mounter/9SM471PLUS.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/sm481plus',
        name: 'sm481plus',
        component: () => import('../views/products/production_equipement/Chip_Mounter/10SM481PLUS.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/sm482plus',
        name: 'sm482plus',
        component: () => import('../views/products/production_equipement/Chip_Mounter/11SM482PLUS.vue'),
        
    },
    {
        path: '/production_equipement/chip_mounter/12scm1-d',
        name: '12scm1',
        component: () => import('../views/products/production_equipement/Chip_Mounter/12SCM1-D.vue'),
        
    },
    {
        path: '/production_equipement/reflow_oven',
        name: 'reflow_oven',
        component: () => import('../views/products/production_equipement/Reflow_Oven/reflow_oven.vue'),
        
    },
    {
        path: '/production_equipement/reflow_oven/hotflow_three',
        name: 'hotflow_three',
        component: () => import('../views/products/production_equipement/Reflow_Oven/1HOTFLOW THREE.vue'),
        
    },
    {
        path: '/production_equipement/reflow_oven/exos10_20',
        name: 'exos10_20',
        component: () => import('../views/products/production_equipement/Reflow_Oven/2EXOS 10_26.vue'),
        
    },
    {
        path: '/production_equipement/reflow_oven/hotflow4',
        name: 'hotflow4',
        component: () => import('../views/products/production_equipement/Reflow_Oven/3HOTFLOW 4.vue'),
        
    },
    {
        path: '/production_equipement/reflow_oven/hotflow3',
        name: 'hotflow3',
        component: () => import('../views/products/production_equipement/Reflow_Oven/4HOTFLOW 3.vue'),
        
    },
    {
        path: '/production_equipement/reflow_oven/hotflow_3e',
        name: 'hotflow_3e',
        component: () => import('../views/products/production_equipement/Reflow_Oven/5HOTFLOW 3E.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering',
        name: 'selective_soldering',
        component: () => import('../views/products/production_equipement/Selective_Soldering/selective_soldering.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflowone',
        name: 'versaflowone',
        component: () => import('../views/products/production_equipement/Selective_Soldering/1VERSAFLOW ONE.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflow4_55',
        name: 'versaflow4_55',
        component: () => import('../views/products/production_equipement/Selective_Soldering/2VERSAFLOW 4_55.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflow4_xl',
        name: 'versaflow4_xl',
        component: () => import('../views/products/production_equipement/Selective_Soldering/3VERSAFLOW 4 XL.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflux',
        name: 'versaflux',
        component: () => import('../views/products/production_equipement/Selective_Soldering/4VERSAFLUX & VERSAFLUX.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflow3_66xl',
        name: 'versaflow3_66xl',
        component: () => import('../views/products/production_equipement/Selective_Soldering/5VERSAFLOW 3_66 XL.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflow3_66',
        name: 'versaflow3_66',
        component: () => import('../views/products/production_equipement/Selective_Soldering/6VERSAFLOW 3_66.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflow3_45',
        name: 'versaflow3_45',
        component: () => import('../views/products/production_equipement/Selective_Soldering/7VERSAFLOW 3_45.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaflow3_35',
        name: 'versaflow3_35',
        component: () => import('../views/products/production_equipement/Selective_Soldering/8VERSAFLOW 3_35 GLOBAL EDITION.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/smartflow2020',
        name: 'smartflow2020',
        component: () => import('../views/products/production_equipement/Selective_Soldering/9SMARTFLOW 2020.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/ecoselect4',
        name: 'ecoselect4',
        component: () => import('../views/products/production_equipement/Selective_Soldering/10ECOSELECT 4.vue'),
        
    },
    {
        path: '/production_equipement/ecoselect1',
        name: 'ecoselect1',
        component: () => import('../views/products/production_equipement/Selective_Soldering/11ECOSELECT 1.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/powerflux',
        name: 'powerflux',
        component: () => import('../views/products/production_equipement/Selective_Soldering/12POWERFLUX.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/versaeye',
        name: 'versaeye',
        component: () => import('../views/products/production_equipement/Selective_Soldering/13VERSAEYE.vue'),
        
    },
    {
        path: '/production_equipement/selective_soldering/cad_assistent4',
        name: 'cad_assistent4',
        component: () => import('../views/products/production_equipement/Selective_Soldering/14CAD-Assistent 4.vue'),
        
    },

    {
        path: '/production_equipement/wave_soldering',
        name: 'wave_soldering',
        component: () => import('../views/products/production_equipement/Wave_Soldering/wave_soldering.vue'),
        
    },
    {
        path: '/production_equipement/wave_soldering/powerflow_ultra_xxl',
        name: 'powerflow_ultra_xxl',
        component: () => import('../views/products/production_equipement/Wave_Soldering/1POWERFLOW ULTRA XXL.vue'),
        
    },
    {
        path: '/production_equipement/wave_soldering/powerflow_ultra_xl',
        name: 'powerflow_ultra_xl',
        component: () => import('../views/products/production_equipement/Wave_Soldering/2POWERFLOW ULTRA XL.vue'),
        
    },
    {
        path: '/production_equipement/wave_soldering/powerflow_ultra',
        name: 'powerflow_ultra',
        component: () => import('../views/products/production_equipement/Wave_Soldering/3POWERFLOW ULTRA.vue'),
        
    },
    {
        path: '/production_equipement/wave_soldering/powerflow_pro',
        name: 'powerflow_pro',
        component: () => import('../views/products/production_equipement/Wave_Soldering/4POWERFLOW PRO.vue'),
        
    },
    {
        path: '/production_equipement/wave_soldering/powerflow',
        name: 'powerflow',
        component: () => import('../views/products/production_equipement/Wave_Soldering/5POWERFLOW.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting',
        name: 'glue_potting',
        component: () => import('../views/products/production_equipement/Glue_potting_Machine/Glue_potting_machine.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgb730',
        name: 'pgb730',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/1Intelligent Glue Potting Machine PGB-730.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/sz441',
        name: 'sz441',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/2Intelligent vision piezoelectric spraying valve glue dispenser SZV441.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgb810',
        name: 'pgb810',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/3High Precision Glue Potting Machine PGB-810.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgb1500',
        name: 'pgb1500',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/4Glue Potting Machine PGB-1500.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgb200',
        name: 'pgb200',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/5 AB Glue potting machine PGB-200.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgb5100',
        name: 'pgb5100',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/6Two components silicone glue dispensing machine PGB-5100 .vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/dpj100',
        name: 'dpj100',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/7Large Flow Glue Potting Machine DPJ-100.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/tg50',
        name: 'tg50',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/8High Precision Glue Potting Machine TG-50.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/qgb400',
        name: 'qgb400',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/9Glue Potting Machine For Product Line QGB-400 .vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgv502',
        name: 'pgv502',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/10Large Vision Visual Glue Potting Machine PGV-502.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgv3000',
        name: 'pgv3000',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/11Visual Glue Potting Machine PGV-3000.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pjl1000',
        name: 'pjl1000',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/12Economy Glue Potting Machine PJL-1000.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/vgb600',
        name: 'vgb600',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/13Standalone Vacuum Glue Potting Machine VGB-600.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/vgb450',
        name: 'vgb450',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/14Vacuum Glue Potting Machine With Production Line VGB-450.vue'),
        
    },
    {
        path: '/production_equipement/glue_potting/pgv501',
        name: 'pgv501',
component: () => import('../views/products/production_equipement/Glue_potting_Machine/15Vision glue potting machine PGV501.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing',
        name: 'pgv501',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/Glue_Dispensing_Machine.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/szd1200',
        name: 'szd1200',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/1Fully automatic glue dispenser machine SZD-1200.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/szd1500',
        name: 'szd1500',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/2One part  glue dispenser SZD1500.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/szd700',
        name: 'szd700',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/3Fully automatic glue dispenser machine SZD-700.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/tg40',
        name: 'tg40',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/4High precision glue dispensing machine TG40.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/pgb700',
        name: 'pgb700',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/5AB part Glue dispenser Machine PGB-700.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/sd200',
        name: 'sd200',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/6Manual operation glue dispenser SD-200.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/szd300',
        name: 'szd300',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/7glue dispenser machine SZD-300.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/pgb1500',
        name: 'pgb1500',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/8AB two components glue machine PGB-1500 .vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/gnd700',
        name: 'gnd700',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/9High Viscosity Glue Dispensing Machine GND-700.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/pgb650x',
        name: 'pgb650x',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/10Double Preparation Glue Filling Machine PGB-650X.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/pjl1200',
        name: 'pjl1200',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/11 Standard Glue Dispensing Machine PJL-1200.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/qpd300',
        name: 'qpd300',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/12Disc Bulb Glue Dispensing Machine QPD-300.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/desktop',
        name: 'desktop',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/13Desktop Glue Dispenser.vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/pgb700c',
        name: 'pgb700',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/14AB components glue dispensing machine PGB-700 .vue'),
        
    },
    {
        path: '/production_equipement/glue_dispensing/pgb1200',
        name: 'pgb1200',
component: () => import('../views/products/production_equipement/Glue_Dispensing_Machine/15AB part Glue dispenser Machine PGB-1200.vue'),
        
    },
    {
        path: '/production_equipement/routerdepann',
        name: 'routerdepann',
component: () => import('../views/products/production_equipement/Router_Depaneling/router_depaneling.vue'),
        
    },
    {
        path: '/production_equipement/procleaning',
        name: 'procleaning',
component: () => import('../views/products/production_equipement/Cleaning_Equipment/cleaning_equipement.vue'),
        
    },
    








 
    //soldering_rework
    {
        path: '/soldering',
        name: 'soldering',
        component: () => import('../views/Categories/soldering_rework.vue'),
        
    },
    {
        path: '/soldering_rework/convection_rework',
        name: 'convection_rework',
        component: () => import('../views/subcategories/Soldering_rework/convection_rework.vue'),
        
    },
    {
        path: '/soldering_rework/soldering',
        name: 'soldering_rework',
        component: () => import('../views/subcategories/Soldering_rework/soldering.vue'),
        
    },{
        path: '/soldering_rework/desoldering',
        name: 'desoldering',
        component: () => import('../views/products/soldering_rework/Desoldering/desoldering.vue'),
        
    },
   



 //km coroporate
 {
    path: '/km',
    name: 'km',
    component: () => import('../views/Categories/km.vue'),
    
},
{
    path: '/km/idc_solutions',
    name: 'idc_solutions',
    component: () => import('../views/products/Km coroporate/1idc solutions/idcsolution.vue'),
    
},
{
    path: '/km/idc_solutions/mic25',
    name: 'mic25',
    component: () => import('../views/products/Km coroporate/1idc solutions/1mic25.vue'),
    
},
{
    path: '/km/idc_solutions/easys',
    name: 'easys',
    component: () => import('../views/products/Km coroporate/1idc solutions/2easy s.vue'),
    
},
{
    path: '/km/idc_solutions/intercons',
    name: 'intercons',
    component: () => import('../views/products/Km coroporate/1idc solutions/3intercon s.vue'),
    
},
{
    path: '/km/idc_solutions/interconxh',
    name: 'interconxh',
    component: () => import('../views/products/Km coroporate/1idc solutions/4intercon xh.vue'),
    
},
{
    path: '/km/idc_solutions/sharkxh',
    name: 'sharkxh',
    component: () => import('../views/products/Km coroporate/1idc solutions/5shark xh.vue'),
    
},
{
    path: '/km/idc_solutions/chain',
    name: 'idcchain',
    component: () => import('../views/products/Km coroporate/1idc solutions/6chain.vue'),
    
},
{
    path: '/km/automatic_solutions',
    name: 'automatic_solutions',
    component: () => import('../views/products/Km coroporate/2Automatic Solutions/automatic solution.vue'),
    
},
{
    path: '/km/automatic_solutions/extreme',
    name: 'automatic_solutionsextreme',
    component: () => import('../views/products/Km coroporate/2Automatic Solutions/1extreme.vue'),
    
},
{
    path: '/km/automatic_solutions/contactst',
    name: 'contactst',
    component: () => import('../views/products/Km coroporate/2Automatic Solutions/2contact st5.vue'),
    
},
{
    path: '/km/automatic_solutions/contactdwf5',
    name: 'contactdwf5',
    component: () => import('../views/products/Km coroporate/2Automatic Solutions/3contactDWf5.vue'),
    
},
{
    path: '/km/automatic_solutions/contacttr',
    name: 'contacttr',
    component: () => import('../views/products/Km coroporate/2Automatic Solutions/4contacttr.vue'),
    
},
{
    path: '/km/automatic_solutions/lynxds5',
    name: 'lynxds5',
    component: () => import('../views/products/Km coroporate/2Automatic Solutions/5lynxds5.vue'),
    
},
{
    path: '/km/crimpingunit',
    name: 'crimpingunit',
    component: () => import('../views/products/Km coroporate/3Crimping Unit/crimping unit.vue'),
    
},
{
    path: '/km/crimpingunit/kn21',
    name: 'kn21',
    component: () => import('../views/products/Km coroporate/3Crimping Unit/1kn21.vue'),
    
},
{
    path: '/km/crimpingunit/kn30s',
    name: 'kn30s',
    component: () => import('../views/products/Km coroporate/3Crimping Unit/2kn30s.vue'),
    
},
{
    path: '/km/crimpingunit/km3011p',
    name: 'km3011p',
    component: () => import('../views/products/Km coroporate/3Crimping Unit/3km3011p.vue'),
    
},
{
    path: '/km/crimpingunit/km3011b',
    name: 'km3011b',
    component: () => import('../views/products/Km coroporate/3Crimping Unit/4km3011b.vue'),
    
},
{
    path: '/km/crimpingunit/km1110',
    name: 'km1110',
    component: () => import('../views/products/Km coroporate/3Crimping Unit/5km1110.vue'),
    
},
{
    path: '/km/accessoiries',
    name: 'kmaccessoiries',
    component: () => import('../views/products/Km coroporate/4Accessoiries/accessoiries.vue'),
    
},
{
    path: '/km/accessoiries/km100',
    name: 'km100',
    component: () => import('../views/products/Km coroporate/4Accessoiries/1km100.vue'),
    
},
{
    path: '/km/accessoiries/km6020',
    name: 'km6020',
    component: () => import('../views/products/Km coroporate/4Accessoiries/2km6020.vue'),
    
},
{
    path: '/km/mini_applicators',
    name: 'miniapplicator',
    component: () => import('../views/products/Km coroporate/5Mini Applicators/miniapplicators.vue'),
    
},
{
    path: '/km/mini_applicators/standard',
    name: 'miniapplicatorstandard',
    component: () => import('../views/products/Km coroporate/5Mini Applicators/1standard mini aplicator .vue'),
    
},
{
    path: '/km/mini_applicators/pneumatic',
    name: 'miniapplicatorpneumatic',
    component: () => import('../views/products/Km coroporate/5Mini Applicators/2pneumatic mini applicator.vue'),
    
},
{
    path: '/km/mini_applicators/ferrules',
    name: 'miniapplicatorferrules',
    component: () => import('../views/products/Km coroporate/5Mini Applicators/3ferrules mini applicator.vue'),
    
},
{
    path: '/km/mini_applicators/special',
    name: 'miniapplicatorspecial',
    component: () => import('../views/products/Km coroporate/5Mini Applicators/4special mini applicator.vue'),
    
},
{
    path: '/km/mini_applicators/splice',
    name: 'miniapplicatorsplice',
    component: () => import('../views/products/Km coroporate/5Mini Applicators/5splice mini applicator.vue'),
    
},

    //inspection_testing
    {
        path: '/inspection',
        name: 'inspection',
        component: () => import('../views/Categories/inspection_testing.vue'),
        
    },
    {
        path: '/inspection_test/aoi',
        name: 'aoi',
        component: () => import('../views/subcategories/inspection_test/aoi.vue'),
        
    },
    {
        path: '/inspection_test/heat_cold_testing',
        name: 'heat_cold_testing',
        component: () => import('../views/subcategories/inspection_test/heat_cold_testing'),
        
    },
    {
        path: '/inspection_test/microscope',
        name: 'microscope',
        component: () => import('../views/subcategories/inspection_test/microscope.vue'),
        
    },

   





    

    

    //materials
    {
        path: '/materials',
        name: 'materials',
        component: () => import('../views/Categories/materials.vue'),
        
    },
    {
        path: '/materials/spray_solution',
        name: 'spray_solution',
        component: () => import('../views/subcategories/materials/spray_solution.vue'),
        
    },
  



    //auxilaryequipement
    {
        path: '/auxilaryequipement',
        name: 'auxiliaryequipement',
        component: () => import('../views/Categories/Auxiliary_equipement.vue'),
        
    },
    {
        path: '/auxiliary_equipement/adhesif_and_liquid_dispenser',
        name: 'adhesif_and_liquid_dispenser',
        component: () => import('../views/subcategories/auxiliary_equipement/adhesif_and_liquid_dispenser.vue'),
        
    },
    {
        path: '/auxiliary_equipement/perform_machine',
        name: 'perform_machine',
        component: () => import('../views/subcategories/auxiliary_equipement/perform_machine.vue'),
        
    },
    {
        path: '/auxiliary_equipement/pneumatique_screw_device',
        name: 'pneumatique_screw_device',
        component: () => import('../views/subcategories/auxiliary_equipement/pneumatique_screw_devices.vue'),
        
    },
    {
        path: '/auxiliary_equipement/electriquescrew',
        name: 'electriquescrew',
        component: () => import('../views/products/Auxiliary_equipement/Electric_Torque_Screwdrivers/Electric_Torque_Screwdrivers.vue'),
        
    },


//esd_tools
{
    path: '/esdtools',
    name: 'esdtools',
    component: () => import('../views/Categories/esd_tools.vue'),
    
},
{
    path: '/esdtools/heelgrounders',
    name: 'heelgrounders',
    component: () => import('../views/products/esd_tools/Anti_Static_Heel_Grounders/anti_static_heel_grounders.vue'),
    
},
{
    path: '/esdtools/wriststraps',
    name: 'wriststraps',
    component: () => import('../views/products/esd_tools/Antistatic_Wrist_Straps/antistatic_wrist_straps.vue'),
    
},
{
    path: '/esdtools/cords',
    name: 'cords',
    component: () => import('../views/products/esd_tools/Earth_Grounding_Cords/earth_grounding_cords.vue'),
    
},
{
    path: '/esdtools/cords',
    name: 'cords',
    component: () => import('../views/products/esd_tools/Earth_Grounding_Cords/earth_grounding_cords.vue'),
    
},
{
    path: '/esdtools/mats_and_kits',
    name: 'mats_and_kits',
    component: () => import('../views/products/esd_tools/ESD_Mats_And_Kits/esd_mats_and_kits.vue'),
    
},
{
    path: '/esdtools/gloves',
    name: 'gloves',
    component: () => import('../views/products/esd_tools/ESD_Gloves/esd_gloves.vue'),
    
},
{
    path: '/esdtools/handtools',
    name: 'handtools',
    component: () => import('../views/products/esd_tools/ESD_Hand_Tools/esd_hand_tools.vue'),
    
},
{
    path: '/esdtools/brusheswipes',
    name: 'brusheswipes',
    component: () => import('../views/products/esd_tools/ESD_Brush_&_Wipes/esd_brush_&_wipes.vue'),
    
},
{
    path: '/esdtools/chairs',
    name: 'chairs',
    component: () => import('../views/products/esd_tools/ESD Chairs/esd_chairs.vue'),
    
},
{
    path: '/esdtools/stationarylamps',
    name: 'stationarylamps',
    component: () => import('../views/products/esd_tools/ESD_Stationary_&_Magnifier_Lamps/ESD_Stationary_&_Magnifier_Lamps.vue'),
    
},
{
    path: '/esdtools/hloderrack',
    name: 'hloderrack',
    component: () => import('../views/products/esd_tools/ESD_PCB_Holders_&_Racks/esd_pcb_holders_&_racks.vue'),
    
},
{
    path: '/esdtools/tester',
    name: 'tester',
    component: () => import('../views/products/esd_tools/Tester_&_Tapes_&_Others/Tester_&_Tapes_&_Others.vue'),
    
},
{
    path: '/esdtools/wire',
    name: 'wire',
    component: () => import('../views/products/esd_tools/Wire_Sheving_&_Cage/Wire_Sheving_&_Cage.vue'),
    
},

    
    
    
   
]
