import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)


const removerAcentos = (string) => {
  const mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g
  }

  for (let letra in mapaAcentosHex) {
    var expressaoRegular = mapaAcentosHex[letra]
    string = string.replace(expressaoRegular, letra)
  }

  return string
}
export default new Vuex.Store({
  state: {
            products:[],
            cart:[], 
            order:{},
            product:{},
            searchWord: null,
            filteredCourses: null,
  },
  mutations: {
         

            SET_COURSE (state, product) {
                state.product = product
              },
            FILTERED_COURSES (state, word) {
                if (!(word) || word === '{}') {
                  state.searchWord = null
                  state.filteredCourses = null
              } else {
                  state.searchWord = word
                  word = removerAcentos(word.trim().toLowerCase())
                  state.filteredCourses = state.products.filter((product) => {
               return product.slug.toLowerCase().includes(word) || product.name.toLowerCase().includes(word) 
              })
             }
            },
          updateProducts(state,products){
                state.products=products;
                },
           addToCart(state,product){
                let productInCartIndex=state.cart.findIndex(item=>item.slug==product.slug);
                if(productInCartIndex!=-1){
                  state.cart[productInCartIndex].quantity++;
                return;
                }
                  product.quantity=1;
                  state.cart.push(product);
                },
            removeFromCart(state,index){
                  state.cart.splice(index,1);
                },
            updateOrder(state,order){
                  state.order=order;
                },
            updateCart(state,cart){
                  state.cart=cart;
                }
              },
  actions: {
          
            getProducts({ commit }){
              axios.get('http://127.0.0.1:8000/api/products')
              .then((response) => {
              commit('updateProducts',response.data);
              })
              .catch((error)=>console.error(error));
              },
            clearCart({ commit }){
                commit('updateCart',[]);
              },
            SET_COURSE ({ commit }, product) {
                commit('SET_COURSE', product)
              },
            FILTERED_COURSES ({ commit }, word) {
                commit('FILTERED_COURSES', word)
              }
          },
  getters: {

             

              allCourses: (state) => state.products,

              getCourse: (state) => state.product,

              getSearchWord: (state) => state.searchWord,

              getFilteredCourse: (state) => state.filteredCourses
  },
  modules: {
  }
})
