import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import store from './store'
import vco from "v-click-outside"
import Embed from 'v-video-embed'
import Invoice from 'vue-invoice'
import VueBarcode from 'vue-barcode'
import ToggleButton from 'vue-js-toggle-button'
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

import router from './router'

import VuePictureSwipe from 'vue-picture-swipe';


import "@/assets/scss/app.scss";


Vue.component('vue-picture-swipe', VuePictureSwipe);

Vue.filter('formatMoney', (value) => {
    return Number(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
})




Vue.use(VueSimpleAccordion, {
  // ... Options go here
});

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.component('barcode', VueBarcode)

Vue.use(BootstrapVue)
Vue.use(Invoice, { store } )
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(Embed)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(ToggleButton)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBr54Ht0zuPgTaZsQNQVTNvSet--40lmSg',
    libraries: 'places',
  },
  installComponents: true
})

var converter = require('number-to-words');

Vue.filter('toWords', function (value) {
  if (!value) return '';
  return converter.toWords(value);
})

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    store.dispatch('getProducts')
    // eslint-disable-next-line no-unused-vars
    .then(_ =>{})
    .catch((error)=> console.error(error));

  },
}).$mount('#app')
